import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import { InitializeError } from "@/core/lib/common.lib";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    Vue.axios.defaults.headers.common["Accept"] = "application/json";
    Vue.axios.defaults.trailingSlash = false;

    Vue.axios.interceptors.request.use(
      function (config) {
        if (config.url.endsWith("/")) {
          config.url = config.url.slice(0, -1);
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    Vue.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const { response } = error;

        const authToken = JwtService.getToken();

        if (authToken && response && response.status === 401) {
          JwtService.destroyToken();
        }

        return Promise.reject(error);
      }
    );
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    console.log({ getToken: JwtService.getToken() });
    console.log({ getTokenType: JwtService.getTokenType() });
    if (JwtService.getTokenType() && JwtService.getToken()) {
      Vue.axios.defaults.headers.common[
        "Authorization"
      ] = `${JwtService.getTokenType()} ${JwtService.getToken()}`;
    }
  },

  query(resource, params) {
    return new Promise((resolve, reject) => {
      Vue.axios
        .get(resource, { params: params })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          let formattedError = InitializeError(response);
          reject(formattedError);
        });
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return new Promise((resolve, reject) => {
      Vue.axios
        .get(`${resource}/${slug}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          let formattedError = InitializeError(response);
          reject(formattedError);
        });
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return new Promise((resolve, reject) => {
      Vue.axios
        .post(resource, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          let formattedError = InitializeError(response);
          reject(formattedError);
        });
    });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return new Promise((resolve, reject) => {
      Vue.axios
        .put(`${resource}/${slug}`, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          let formattedError = InitializeError(response);
          reject(formattedError);
        });
    });
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return new Promise((resolve, reject) => {
      Vue.axios
        .put(resource, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          let formattedError = InitializeError(response);
          reject(formattedError);
        });
    });
  },

  /**
   * Send the PATCH HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  patch(resource, params) {
    return new Promise((resolve, reject) => {
      Vue.axios
        .patch(resource, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          let formattedError = InitializeError(response);
          reject(formattedError);
        });
    });
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return new Promise((resolve, reject) => {
      Vue.axios
        .delete(resource)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          let formattedError = InitializeError(response);
          reject(formattedError);
        });
    });
  },

  /**
   * Send the UPLOAD HTTP request
   * @param resource
   * @returns {*}
   */
  upload(resource, params) {
    return new Promise((resolve, reject) => {
      Vue.axios
        .post(resource, params, { headers: { "content-type": "multipart/form-data" } })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          let formattedError = InitializeError(response);
          reject(formattedError);
        });
    });
  },
};

export default ApiService;
