import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
// import { VERIFY_AUTH } from '@/core/services/store/auth.module';
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import MainSetting from "@/core/mixins/main.mixin";
import VueMask from "v-mask";
import PortalVue from "portal-vue";
import CKEditor from "@ckeditor/ckeditor5-vue2";


Vue.use(VueMask);

Vue.use(PortalVue);
Vue.use(CKEditor);

Vue.use(MainSetting);

Vue.config.productionTip = false;

Vue.prototype.$APPNAME = process.env.VUE_APP_NAME;

Vue.prototype.$assetURL = (param) => {
  if (!param) {
    return process.env.VUE_APP_BASE_URL + "media/users/default.jpg";
  }
  return process.env.VUE_APP_BASE_URL + param;
};

// Global 3rd party plugins
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;

// Vue 3rd party plugins
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/inline-svg";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";

// API service init
ApiService.init();

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  // Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
  next();
  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
