import Vue from "vue";
import ApiService from "@/core/services/api.service";

export const COMMON_EVENT_BUS = new Vue();

export const GET_PROFILE = () => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get("profile")
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const UPDATE_PROFILE = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put("profile", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const GET_BRAND = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query("option/brand", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};
export const GET_CATEGORY = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query("option/category", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};
export const GET_UNITS = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query("option/unit", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const GET_SUPPLIERCATEGORY = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query("option/add_on_category", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};
export const GET_DEPARTMENT = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query("option/cuisine", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};
export const UPDATE_DEPARTMENT = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put("option/cuisine", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const UPDATE_CATEGORY = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("store-department", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((response) => {
        reject(response);
      });
  });
};
export const UPDATE_BUDGETCATEGORY = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("store-budget", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((response) => {
        reject(response);
      });
  });
};

export const UPDATE_BRAND = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put("option/brand", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const UPDATE_UNITS = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put("option/unit", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const UPDATE_SUPPLIERCATEGORY = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put("option/add_on_category", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const GET_CUISINE = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query("option/cuisine", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const UPDATE_CUISINE = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put("option/cuisine", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const GET_SUB_CATEGORY = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query("option/sub_category", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const UPDATE_SUB_CATEGORY = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put("option/sub_category", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const GET_ADD_ON_CATEGORY = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query("option/add_on_category", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const UPDATE_ADD_ON_CATEGORY = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put("option/add_on_category", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const GET_PROMOTIONS = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query("promotion", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const CREATE_PROMOTION = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("promotion", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};
export const CREATE_PROMOTIONS = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("create-promotion", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const UPDATE_PROMOTION = (id, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`promotion/${id}`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};
export const UPDATE_PROMOTIONS = (id, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`create-promotion/${id}`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const DELETE_PROMOTION = (id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.delete(`promotion/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const GET_VOUCHERS = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query("voucher", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const CREATE_VOUCHER = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("voucher", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const UPDATE_VOUCHER = (id, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`voucher/${id}`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};
export const UPDATE_DEPTCATEGORY = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`update-department`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((response) => {
        reject(response);
      });
  });
};

export const UPDATE_BUDCATEGORY = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`update-budget`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((response) => {
        reject(response);
      });
  });
};

// export const UPDATE_BUDGETCATEGORY = (params) => {
//   return new Promise((resolve, reject) => {
//     ApiService.setHeader();
//     ApiService.put(`store-budget`, params)
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch(({ response }) => {
//         reject(response);
//       });
//   });
// };

export const UPDATE_SUBDEPTCATEGORY = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`update-sub-department`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((response) => {
        reject(response);
      });
  });
};

export const UPDATE_SUBBUDGETCATEGORY = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`update-sub-budget`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((response) => {
        reject(response);
      });
  });
};

export const CREATE_SUBDEPARTMENT = (id, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`store-sub-department/${id}`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((response) => {
        reject(response);
      });
  });
};

export const CREATE_SUBBUDGET = (id, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`store-sub-budget/${id}`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((response) => {
        reject(response);
      });
  });
};
export const DELETE_VOUCHER = (id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.delete(`voucher/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const GET_AVAILABLE_VOUCHERS = (order_id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`voucher/${order_id}/collect`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const GET_COLLECTED_VOUCHERS = (customer_id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`voucher/${customer_id}/collected`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const GET_ALL_COLLECTED_VOUCHERS = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`voucher/collected`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const GET_VOUCHER_HISTORY = (id, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`voucher/${id}/history`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};
export const GET_PROMOTION = (id, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`find-promotion/${id}`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const COLLECT_VOUCHER = (vcid, orderId) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`voucher/${vcid}/collect/${orderId}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const REDEEM_VOUCHER = (vcid, orderId) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`voucher/${vcid}/redeem/${orderId}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const LINK_ORDER_VOUCHER = (vcid, orderId) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`voucher/${vcid}/order/${orderId}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const GET_ACTIVITY = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query("activity-log", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const InitializeError = (response) => {
  let requestErrors = [];
  let data = response.data;
  if (data && data.message) {
    requestErrors.push(data.message);
  } else if (response && response.status === 422) {
    if (data.errors) {
      for (let error in data.errors) {
        if (data.errors[error]) {
          for (let i = data.errors[error].length - 1; i >= 0; i--) {
            requestErrors.push(data.errors[error][i]);
          }
        }
      }
    } else {
      for (let error in data) {
        if (data[error]) {
          for (let i = data[error].length - 1; i >= 0; i--) {
            requestErrors.push(data[error][i]);
          }
        }
      }
    }
  } else if (response && response.status === 207) {
    requestErrors.push(data.error);
  } else if (response && response.status === 401) {
    requestErrors.push(data.error);
  } else if (response && response.status === 500) {
    requestErrors.push(data.message);
  } else if (response && response.status === 400) {
    requestErrors.push(data.message);
  } else if (response && response.status === 404) {
    requestErrors.push("Sorry ! The page you requested was not found.");
  } else if (response && response.status === 405) {
    requestErrors.push(data.message);
  } else if (typeof response === "string") {
    requestErrors.push(response);
  } else {
    requestErrors.push("Something went wrong, Please try again later.");
  }
  return requestErrors.join("\n");
};

export const UPDATE_SOURCES = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put("option/source", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const GET_SOURCE = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query("option/source", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const GET_EVENTS = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query("option/events", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const UPDATE_EVENTS = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put("option/events", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};
