import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/customer",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "customer",
          name: "customer",
          component: () => import("@/view/module/customer/Customer"),
        },
        {
          path: "reservation",
          name: "reservation",
          component: () => import("@/view/module/reservation/Reservation"),
        },

        //Promotion-----------
        {
          path: "promotion",
          name: "promotion",
          component: () => import("@/view/module/promotion/Promotion"),
        },
        {
          path: "promotion/create",
          name: "promotion-create",
          component: () => import("@/view/module/promotion/Create-Or-Update-Promotion"),
        },
        {
          path: "promotion/update/:id",
          name: "promotion-update",
          component: () => import("@/view/module/promotion/Create-Or-Update-Promotion"),
        },
        {
          path: "promotion/detail/:id",
          name: "promotion-detail",
          component: () => import("@/view/module/promotion/Detail-Promotion"),
        },
        //leave-----------
        {
          path: "leave/create",
          name: "leave",
          component: () => import("@/view/module/leave/Leave"),
        },
        {
          path: "CustomerList/update/:id",
          name: "leave-update",
          component: () => import("@/view/module/leave/CustomerList"),
        },
        //Sales Summary-----------
        {
          path: "salessummary",
          name: "salessummary",
          component: () => import("@/view/module/sales/SalesSummary"),
        },
        {
          path: "ppdate-sales-summary/update/:id",
          name: "sales-update",
          component: () => import("@/view/module/sales/Update-Sales-Summary"),
        },
        {
          path: "salessummary/ppdate-sales-summary/create",
          name: "sales-create",
          component: () => import("@/view/module/sales/Update-Sales-Summary"),
        },
        {
          path: "salessummary/detail/:id",
          name: "sales-detail",
          component: () => import("@/view/module/sales/Detail-Sales-Summary"),
        },
        //Qr Sales

        {
          path: "qrsalessummary",
          name: "qrsalessummary",
          component: () => import("@/view/module/qrsales/SalesSummary"),
        },
        {
          path: "qrsalessummary/create-qr-sales",
          name: "qrsales-create",
          component: () => import("@/view/module/qrsales/Update-Sales-Summary"),
        },
        {
          path: "qrsalessummary/detail/:id",
          name: "qrsales-detail",
          component: () => import("@/view/module/qrsales/Detail-Sales-Summary"),
        },
        {
          path: "qrsalessummary/final/:id",
          name: "qrsales-final",
          component: () => import("@/view/module/qrsales/Final"),
        },
        {
          path: "debitsalessummary",
          name: "debitsalessummary",
          component: () => import("@/view/module/debitsales/SalesSummary"),
        },

        {
          path: "debitsalessummary/detail/:id",
          name: "debitsales-detail",
          component: () => import("@/view/module/debitsales/Detail-Sales-Summary"),
        },

        {
          path: "mastercardsalessummary",
          name: "mastercardsalessummary",
          component: () => import("@/view/module/mastercardsales/SalesSummary"),
        },

        {
          path: "mastercardsalessummary/detail/:id",
          name: "mastercard-detail",
          component: () => import("@/view/module/mastercardsales/Detail-Sales-Summary"),
        },
        {
          path: "visasalessummary",
          name: "visasalessummary",
          component: () => import("@/view/module/visasales/SalesSummary"),
        },

        {
          path: "visasalessummary/detail/:id",
          name: "visa-detail",
          component: () => import("@/view/module/visasales/Detail-Sales-Summary"),
        },
        {
          path: "grabfoodsummary",
          name: "grabfoodsummary",
          component: () => import("@/view/module/grabfoodsales/SalesSummary"),
        },

        {
          path: "grabfoodsummary/detail/:id",
          name: "grab-detail",
          component: () => import("@/view/module/grabfoodsales/Detail-Sales-Summary"),
        },
        {
          path: "onlinesummary",
          name: "onlinesummary",
          component: () => import("@/view/module/onlinesales/SalesSummary"),
        },
        {
          path: "onlinesummary/create-qr-sales",
          name: "online-create",
          component: () => import("@/view/module/onlinesales/Update-Sales-Summary"),
        },
        {
          path: "onlinesummary/detail/:id",
          name: "online-detail",
          component: () => import("@/view/module/onlinesales/Detail-Sales-Summary"),
        },
        {
          path: "onlinesummary/final/:id",
          name: "online-final",
          component: () => import("@/view/module/onlinesales/Final"),
        },
        {
          path: "totalsalessummary",
          name: "totalsalessummary",
          component: () => import("@/view/module/totalsales/SalesSummary"),
        },

        {
          path: "touchgosummary",
          name: "touchgosummary",
          component: () => import("@/view/module/touchgo/SalesSummary"),
        },
        {
          path: "touchgosummary/detail/:id",
          name: "touch-detail",
          component: () => import("@/view/module/touchgo/Detail-Sales-Summary"),
        },

        //Advance listing-----------
        {
          path: "advance/create",
          name: "advance",
          component: () => import("@/view/module/advance/Advance"),
        },
        {
          path: "Advance-Update/update/:id",
          name: "advance-update",
          component: () => import("@/view/module/advance/Advance-Update"),
        },
        //customer---------
        {
          path: "customer/create",
          name: "customer-create",
          component: () => import("@/view/module/customer/Create-Or-Update-Customer"),
        },
        {
          path: "customer/update/:id",
          name: "customer-update",
          component: () => import("@/view/module/customer/Create-Or-Update-Customer"),
        },
        {
          path: "customer/detail/:id",
          name: "customer-detail",
          component: () => import("@/view/module/customer/Detail-Customer"),
        },
        //Item---------
        {
          path: "item",
          name: "item",
          component: () => import("@/view/module/item/Item"),
        },
        {
          path: "item/create",
          name: "item-create",
          component: () => import("@/view/module/item/Create-Or-Update-Item"),
        },
        {
          path: "item/update/:id",
          name: "item-update",
          component: () => import("@/view/module/item/Create-Or-Update-Item"),
        },
        {
          path: "item/detail/:id",
          name: "item-detail",
          component: () => import("@/view/module/item/Detail-Item"),
        },
        //Stock Count---------
        //  {
        //   path: "stock-counts",
        //   name: "stock-counts",
        //   component: () => import("@/view/module/stock-counts/Stock-Counts"),
        // },
        // {
        //   path: "stock-counts/create",
        //   name: "stock-counts-create",
        //   component: () => import("@/view/module/stock-counts/Create-Or-Update-Stock"),
        // },
        // {
        //   path: "stock-counts/update/:id",
        //   name: "stock-counts-update",
        //   component: () => import("@/view/module/stock-counts/Create-Or-Update-Stock"),
        // },
        // {
        //   path: "stock-counts/detail/:id",
        //   name: "stock-counts-detail",
        //   component: () => import("@/view/module/stock-counts/Detail-Stock-Count"),
        // },
        // purchase-order

        {
          path: "purchase-order",
          name: "purchase-order",
          component: () => import("@/view/module/purchase-order/Purchase-Order"),
        },
        {
          path: "purchase-order/create",
          name: "purchase-order-create",
          component: () => import("@/view/module/purchase-order/Create-Or-Update-Purchase-Order"),
        },
        {
          path: "purchase-order/update/:id",
          name: "purchase-order-update",
          component: () => import("@/view/module/purchase-order/Create-Or-Update-Purchase-Order"),
        },
        {
          path: "purchase-order/detail/:id",
          name: "purchase-order-detail",
          component: () => import("@/view/module/purchase-order/Detail-Purchase-Order"),
        },
        // Bills
        {
          path: "bills",
          name: "bills-listing",
          component: () => import("@/view/module/bills/BillsListing"),
        },
        {
          path: "bills/create",
          name: "bills-create",
          component: () => import("@/view/module/bills/Create-Or-Update-Bills"),
        },
        {
          path: "bills/update/:id",
          name: "bills-update",
          component: () => import("@/view/module/bills/Create-Or-Update-Bills"),
        },
        {
          path: "bills/detail/:id",
          name: "bills-detail",
          component: () => import("@/view/module/bills/Detail-Bills"),
        },

        // Payment Made
        {
          path: "payment-made",
          name: "payment-made-listing",
          component: () => import("@/view/module/payment-made/PaymentMadeBillsListing"),
        },
        {
          path: "payment-made/create",
          name: "payment-made-create",
          component: () => import("@/view/module/payment-made/Create-Or-Update-PaymentBills"),
        },
        {
          path: "payment-made/update/:id",
          name: "payment-made-update",
          component: () => import("@/view/module/payment-made/Create-Or-Update-PaymentBills"),
        },
        {
          path: "payment-made/detail/:id",
          name: "payment-made-detail",
          component: () => import("@/view/module/payment-made/Detail-PaymentMadeBills"),
        },
        // Payment Made
        {
          path: "setting",
          name: "setting",
          component: () => import("@/view/module/setting/Setting"),
        },
        {
          path: "blast-notification",
          name: "blast-notification",
          component: () => import("@/view/module/blast-notification/Blast-Notification"),
        },
        {
          path: "blast-notification",
          name: "blast-notification-create",
          component: () => import("@/view/module/blast-notification/Create-Or-Update-Notification"),
        },
        // -----------------users--------------
        {
          name: "users",
          path: "users",
          component: () => import("@/view/module/user/Users.vue"),
        },
        {
          path: "users/add",
          name: "users-add",
          component: () => import("@/view/module/user/Create-Or-Update-Users.vue"),
        },
        {
          path: "users/update/:id",
          name: "users-update",
          component: () => import("@/view/module/user/Create-Or-Update-Users.vue"),
        },
        {
          path: "users/detail/:id",
          name: "user-detail",
          component: () => import("@/view/module/user/Detail-Users.vue"),
        },
        // --------------------end users----------------
        {
          path: "broadcast",
          name: "broadcast",
          component: () => import("@/view/module/marketing/broadcast.vue"),
        },
        {
          path: "broadcast/create",
          name: "create-broadcast",
          component: () => import("@/view/module/marketing/Create-Broadcast.vue"),
        },
        {
          path: "broadcast/update/:id",
          name: "update-broadcast",
          component: () => import("@/view/module/marketing/Create-Broadcast.vue"),
        },
        {
          path: "broadcast/detail/:id",
          name: "broadcast-detail",
          component: () => import("@/view/module/marketing/Broadcast-Details"),
        },
        {
          path: "broadcast",
          name: "broadcast",
          component: () => import("@/view/module/marketing/broadcast.vue"),
        },
        {
          path: "segment",
          name: "segment",
          component: () => import("@/view/module/marketing/Segment"),
        },
        {
          path: "template",
          name: "template",
          component: () => import("@/view/module/marketing/Template"),
        },
        {
          path: "template/detail/:id",
          name: "template-detail",
          component: () => import("@/view/module/marketing/Template-Detail"),
        },
        {
          path: "template/create",
          name: "create-template",
          component: () => import("@/view/module/marketing/Create-Template"),
        },
        {
          path: "segment",
          name: "segment",
          component: () => import("@/view/module/marketing/Segment"),
        },
        {
          path: "template/update/:id",
          name: "update-template",
          component: () => import("@/view/module/marketing/Create-Template"),
        },
        {
          path: "segment/detail/:id",
          name: "segment-detail",
          component: () => import("@/view/module/marketing/Segment-Detail"),
        },
        {
          path: "employee",
          name: "employee",
          component: () => import("@/view/module/employee/Employee.vue"),
        },
        {
          path: "employee",
          name: "employee",
          component: () => import("@/view/module/employee/Employee.vue"),
        },
        {
          path: "create-employee",
          name: "create-employee",
          component: () => import("@/view/module/employee/Create-Employee.vue"),
        },
        {
          path: "employee/update/:id",
          name: "update-employee",
          component: () => import("@/view/module/employee/Create-Employee.vue"),
        },
        {
          path: "employee/detail/detail/:id",
          name: "employee-detail",
          component: () => import("@/view/module/employee/Detail-Employee"),
        },
        
        {
          name: "products",
          path: "products",
          component: () => import("@/view/module/product/Products.vue"),
        },
        {
          path: "products/add",
          name: "product-add",
          component: () => import("@/view/module/product/Add-Update-Product.vue"),
        },
        {
          path: "products/update/:id",
          name: "product-update",
          component: () => import("@/view/module/product/Add-Update-Product.vue"),
        },
        {
          path: "products/detail/:id",
          name: "product",
          component: () => import("@/view/module/product/Product.vue"),
        },
        {
          path: "add-adjustment",
          name: "Add Adjustment",
          component: () => import("@/view/module/product/Add-Adjustment.vue"),
        },
        //Sock Counts .......
        {
          path: "stock_counts",
          name: "Stock Count",
          component: () => import("@/view/module/product/Stock-Counts"),
        },
        {
          path: "adjustments",
          name: "Adjustments",
          component: () => import("@/view/module/product/Adjustments"),
        },
        {
          path: "adjustments/adjustment-detail/detail/:id",
          name: "adjustment-detail",
          component: () => import("@/view/module/product/Detail-adjustment"),
        },

        {
          path: "products/count_stock",
          name: "Count Stock",
          component: () => import("@/view/module/product/Add-Stock"),
        },
        {
          path: "stock_counts/finalize_count/:id",
          name: "Finalize Count",
          component: () => import("@/view/module/product/Finalize-Count"),
        },
        //Sock Counts .......
        {
          path: "supplier",
          name: "supplier",
          component: () => import("@/view/module/supplier/Supplier"),
        },
        {
          path: "supplier/create",
          name: "create-supplier",
          component: () => import("@/view/module/supplier/Create-Or-Update-Supplier"),
        },
        {
          path: "supplier/update/:id",
          name: "supplier-update",
          component: () => import("@/view/module/supplier/Create-Or-Update-Supplier"),
        },
        {
          path: "supplier/supplier-detail/detail/:id",
          name: "supplier-detail",
          component: () => import("@/view/module/supplier/Detail-Supplier"),
        },
        // -------------------------salary--------------
        {
          path: "salary",
          name: "salary",
          component: () => import("@/view/module/salary/Salary.vue"),
        },
        {
          path: "salary",
          name: "salary",
          component: () => import("@/view/module/salary/Salary.vue"),
        },
        {
          path: "create-salary/:id",
          name: "create-salary",
          component: () => import("@/view/module/salary/Create-Salary.vue"),
        },
        {
          path: "create-salary/update/:id",
          name: "salary-update",
          component: () => import("@/view/module/salary/Create-Salary.vue"),
        },
        {
          path: "salary/detail/:id",
          name: "salary-detail",
          component: () => import("@/view/module/salary/Detail-Salary"),
        },
        {
          path: "salaryDetail/detail/:id",
          name: "salaryDetail",
          component: () => import("@/view/module/salary/SalaryDetails"),
        },
        {
          path: "salaryDetail/history/:id",
          name: "salaryDetailLogTab",
          component: () => import("@/view/module/salary/LogTab"),
        },


        // -------------------------ConsumptionReport--------------
        {
          path: "consumptionReport",
          name: "consumptionReport",
          component: () => import("@/view/module/consumptionReport/ListConsumption.vue"),
        },
        {
          path: "consumptionReport",
          name: "consumptionReport",
          component: () => import("@/view/module/consumptionReport/ListConsumption.vue"),
        },
        {
          path: "purchaseReport",
          name: "purchaseReport",
          component: () => import("@/view/module/purchaseReport/ListConsumption.vue"),
        },
        {
          path: "equipment",
          name: "equipment",
          component: () => import("@/view/module/consumptionReport/Equipment.vue"),
        },
        // {
        //   path: "create-consumptionReport",
        //   name: "create-consumptionReport",
        //   component: () => import("@/view/module/consumptionReport/Create-Consumption-Report.vue"),
        // },Equipment
        {
          path: "consumptionReport/detail/detail/:id",
          name: "consumptionReport-detail",
          component: () => import("@/view/module/consumptionReport/Detail-Consumption"),
        },
        {
          path: "purchaseReport/detail/detail/:id",
          name: "purchaseReport-detail",
          component: () => import("@/view/module/purchaseReport/Detail-Consumption"),
        },
        {
          path: "reservation/detail/:id",
          name: "reservation-detail",
          component: () => import("@/view/module/reservation/Detail-Reservation"),
        },
      ],
    },
    {
      path: "/pos",
      redirect: "/pos/table",
      component: () => import("@/view/module/pos/POS-Layout"),
      children: [
        {
          path: "table",
          name: "pos-table",
          component: () => import("@/view/module/pos/POS"),
        },
        {
          path: "order",
          name: "pos-order",
          component: () => import("@/view/module/pos/POS-Order"),
        },
        {
          path: "checkout",
          name: "pos-checkout",
          component: () => import("@/view/module/pos/POS-Checkout"),
        },
      ],
    },
    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1"),
        },
      ],
    },
    {
      path: "/",
      name: "authenticate",
      component: () => import("@/view/pages/auth/Authenticate"),
      children: [
        {
          path: "login",
          name: "login",
          component: () => import("@/view/pages/auth/Login"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1"),
    },
  ],
});
