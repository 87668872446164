import ApiService from "@/core/services/api.service";

export const GET_CONFIG = () => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get("pos/config")
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_CATEGORIES = () => {CREATE_RECORD
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get("pos/category")
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_ORDERS = () => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get("pos/order")
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_TABLES = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query("pos/table", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_TABLE_ITEMS = (table) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`pos/table-item/${table}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_CUSTOMER = () => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get("pos/customer")
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_ITEMS = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query("pos/item", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_ITEM_CATEGORIES = () => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query("pos/item-category")
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CREATE_CUSTOMER = (table, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`pos/table/${table}/customer`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const UPDATE_TABLE_ITEMS = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`pos/table-item/${params.table}`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CREATE_ORDER = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("pos/order", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const CHECK_PO = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("purchase-order/supplier-check", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const FINISH_TABLE = (table, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`pos/table/${table}/finish`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const APPLY_COUPON = (table, coupon) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`pos/table/${table}/apply-coupon`, { coupon })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const REMOVE_COUPON = (table) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`pos/table/${table}/remove-coupon`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const APPLY_POINT = (table) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`pos/table/${table}/apply-point`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const REMOVE_POINT = (table) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`pos/table/${table}/remove-point`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const DELETE_ITEM = (table, item) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.delete(`pos/table/${table}/item/${item}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CREATE_PO = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("purchase-order", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_PO = (
  bill_no,
  searchsupplier,
  paymentby,
  range,
  search,
  selectstatus,
  currentPage,
  status_filter
) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.patch("purchase-orders", {
      bill_number: bill_no,
      supplier_id: searchsupplier,
      paymentby: paymentby,
      date: range,
      search,
      selectstatus,
      current_page: currentPage,
      status_filter,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GET_All = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    // ApiService.get(`purchase-order/supplier-filter`)
    ApiService.patch("purchase-order/supplier-filter", {
      id: params,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const FIND_PO = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`get-purchase-order/${params}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const FIND_POSS = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`get-purchase/${params}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const FIND_POS = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`get-purchase-orders/${params}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const FIND_POCHECK = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`get-purchase-orders-record`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const FIND_POCHECK_BILL = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`get-purchase-orders-bill`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const UPDATE_PO = (id, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`purchase-order/${id}`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CREATE_RECORD = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("purchase-order/record-payment", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_PAYMENT = () => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get("payment")
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CREATE_LOAN = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("loan", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const UPDATE_LOAN = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`loan`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const PAY_EMI = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`pay-emi`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
