import { AES, enc } from "crypto-js";
import { isEmpty, isObject } from "lodash";
import { /*setCookie,*/ deleteCookie /*, getCookie*/ } from "@/core/plugins/bt-cookie";
import { saveData, deleteData, getData } from "@/core/services/local.service";

const ID_TOKEN_KEY = "access_token";
const ID_TOKEN_TYPE = "token_type";
const ID_TOKEN_EXPIRY = "expires_at";
const ID_CURRENT_USER = "_btusr_tmp";
const ID_MENU_LIST = "_btcsr_tmp";
const ID_PERMISSION_LIST = "_btpsr_tmp";
const ID_CLIENT_DETAILS = "_btcld_tmp";
const ENCRYPTION_KEY = process.env.VUE_APP_ENCRYPTION_KEY;

const destroyAuth = () => {
  deleteCookie(ID_TOKEN_KEY);
  deleteCookie(ID_TOKEN_TYPE);
  deleteCookie(ID_TOKEN_EXPIRY);
  deleteData(ID_CURRENT_USER);
  deleteData(ID_MENU_LIST);
  deleteData(ID_PERMISSION_LIST);
  deleteData(ID_CLIENT_DETAILS);
  deleteData("pos-config");
  window.localStorage.removeItem(ID_TOKEN_TYPE);
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(ID_TOKEN_EXPIRY);
};

const saveLocalStorage = (key, data) => {
  try {
    if (isEmpty(data) === false && isObject(data)) {
      let encrypted = AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
      saveData(key, encrypted);
    }
  } catch (error) {
    console.warn({ error });
  }
};

const getLocalStorage = (key) => {
  try {
    let encrypted = getData(key);
    let decrypted = AES.decrypt(encrypted, ENCRYPTION_KEY);
    return JSON.parse(decrypted.toString(enc.Utf8));
  } catch (error) {
    console.warn({ error });
  }
};

export const isAuthenticated = () => {
  return !!window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getTokenType = () => {
  return window.localStorage.getItem(ID_TOKEN_TYPE);
};

export const getTokenExpiry = () => {
  return window.localStorage.getItem(ID_TOKEN_EXPIRY);
};

export const getCurrentUser = () => {
  return getLocalStorage(ID_CURRENT_USER);
};

export const saveToken = ({ token_type, expires_in, access_token }) => {
  window.localStorage.setItem(ID_TOKEN_TYPE, token_type);
  window.localStorage.setItem(ID_TOKEN_KEY, access_token);
  window.localStorage.setItem(ID_TOKEN_EXPIRY, expires_in);
};

export const saveUser = (data) => {
  saveLocalStorage(ID_CURRENT_USER, data);
};

export const destroyToken = () => {
  destroyAuth();
};

export default {
  getToken,
  getTokenType,
  getTokenExpiry,
  getCurrentUser,
  saveToken,
  saveUser,
  isAuthenticated,
  destroyToken,
};
